import Socket from '@/common/utils/websocket'
export default {
    data() {
        return {
            connections: [],
            loading: false,
            connection: 0, // 0连接中，1已连接，2已断开，3连接失败
            monitorPageDisplay: {
                modeStatus: { name: '当前服务模式', isShow: true, value: '--' },
                direction: { name: '轿厢运行方向', isShow: true, value: '--' },
                floorStatus: { name: '平层状态', isShow: true, value: '--' },
                doorStatus: { name: '厅门状态', isShow: true, value: '--' },
                speed: { name: '当前速度', isShow: true, value: '--' },
                safeLoop: { name: '安全回路状态', isShow: true, value: '--' },
                runStatus: { name: '轿厢运行状态', isShow: true, value: '--' },
                floor: { name: '电梯当前楼层', isShow: true, value: '--' },
                droopClose: { name: '关门到位', isShow: true, value: '--' },
                carStatus: { name: '轿门状态', isShow: true, value: '--' },
                temperature: { name: '机房温度', isShow: true, value: '--' },
                driveStatus: { name: '曳引机状态', isShow: true, value: '--' },
                hasPeople: { name: '轿内是否有人', isShow: true, value: '--' },
            },
            zhendong:false,
            kouzhao:false,
            dangmen:false,
        }
    },
    mounted() {},
    beforeDestroy() {
        this.closeConnection()
        this.FaultList = []
    },
    activated() {},
    deactivated() {},
    methods: {
        getElevatorCode() {},
        initSocket(code, sendors) {
            this.closeConnection()
            this.FaultList = []
            let promiseList = []
            let sockets = []
            sendors.forEach((sensorType) => {
                promiseList.push(new Promise((resolve, reject) => {
                    sockets.push(
                        new Socket({
                            code: code,
                            sensorType: sensorType,
                            onchange: (data) => {
                                this.handleSocketReponse(data, code)
                            },
                            onopen: () => {
                                resolve()
                            },
                            onclose: () => {

                            },
                            onerror: () => {
                                reject()
                            }
                        }))
                }))
            })
            this.connections = sockets
            this.loading = true
            Promise.all(promiseList).then(() => {
                this.loading = false
                this.connection = 1
            }).catch(() => {
                this.loading = false
                this.connection = 3
            })
        },
        closeConnection() {
            if (this.connections && this.connections.length) {
                this.connections.forEach((socket) => {
                    socket.close()
                })
            }
            this.monitorPageDisplay = {
                modeStatus: { name: '当前服务模式', isShow: true, value: '--' },
                direction: { name: '轿厢运行方向', isShow: true, value: '--' },
                floorStatus: { name: '平层状态', isShow: true, value: '--' },
                doorStatus: { name: '厅门状态', isShow: true, value: '--' },
                speed: { name: '当前速度', isShow: true, value: '--' },
                safeLoop: { name: '安全回路状态', isShow: true, value: '--' },
                runStatus: { name: '轿厢运行状态', isShow: true, value: '--' },
                floor: { name: '电梯当前楼层', isShow: true, value: '--' },
                droopClose: { name: '关门到位', isShow: true, value: '--' },
                carStatus: { name: '轿门状态', isShow: true, value: '--' },
                temperature: { name: '机房温度', isShow: true, value: '--' },
                driveStatus: { name: '曳引机状态', isShow: true, value: '--' },
                hasPeople: { name: '轿内是否有人', isShow: true, value: '--' },
            }
        },
        handleSocketReponse(obj, code) {
            // console.log("socket change resp:", response)
            if (code != this.getElevatorCode()) return
            if (obj.type === 'Monitor' || obj.type === 'Monitor') {
                Object.keys(this.monitorPageDisplay).forEach((key) => {
                    if (obj.hasOwnProperty(key)) {
                        this.monitorPageDisplay[key].value = obj[key]
                    }

                })
                // console.log(this.monitorPageDisplay)
            } else if (obj.TY === 'Fault' || obj.tY === 'Fault') {
                if (obj.ST === 'add' || obj.sT === 'add') {
                    let temp = true
                    for (let i = 0; i < this.FaultList.length; i++) {
                        if (this.FaultList[i].fault_type === obj.fault_type) {
                            temp = false
                            break
                        }
                    }
                    if (temp || this.FaultList.length === 0) {
                        this.FaultList.push(obj)
                    }

                    if (obj.fault_type == 38) {
                        this.zhendong = true;
                    }
                    if (obj.fault_type == 39) {
                        this.kouzhao = true;
                    }
                    if (obj.fault_type == 20) {
                        this.dangmen = true;
                    }
                } else if (obj.ST === 'disappear' || obj.sT === 'disappear') {
                    this.FaultList = this.FaultList.filter(i => i.fault_type !== obj.fault_type)
                    if (obj.fault_type == 38) {
                        this.zhendong = false;
                    }
                    if (obj.fault_type == 39) {
                        this.kouzhao = false;
                    }
                    if (obj.fault_type == 20) {
                        this.dangmen = false;
                    }
                }
            }
        }
    }
}